import { Link, useNavigate } from 'react-router-dom';
import './HeaderComponent.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import axios from 'axios';

function HeaderComponent() {
  const { t } = useTranslation();

  const [searchPayload, setSearchPayload] = useState("");

  const navigate = useNavigate();
  
  const actionSearch = function(){
    (async function() {
      try {
        const params = new URLSearchParams({"p_name": searchPayload, "vote_guard": localStorage.getItem("voteGuard") });
        const url = `https://api.people-ranking.com/api/getPeople?${params.toString()}`;
        const response = await axios.get(url);
        const data = response.data;

        if(data.people_id&&data.people_name){
          navigate(`/people/${data.people_name.replaceAll(" ", "-")}-${data.people_id}`);
        }
      } catch (error) {
        console.error('Error while fetching: ', error);
      }
    })();
  }

  return (
    <header className="bg-[#0A1128] h-[80px] flex justify-between items-center p-2">
      <Link className="flex w-[100%] md:w-[33%]" to={"/"}><img src="/logo-pr.svg" className="h-[70%]" alt="logo" /></Link>
      <nav className="hidden md:flex ml-2 items-center gap-4 text-white">
          <Link to={"/top/most_liked"}>{t('HeaderComponent_most_liked')}</Link>
          <Link to={"/top/most_disliked"}>{t('HeaderComponent_less_liked')}</Link>
          <Link to={"/top/most_trendy"}>{t('HeaderComponent_trend')}</Link>
      </nav>
      <div>
        <div className="hidden md:block w-full max-w-sm min-w-[200px]">
          <input
          onKeyDown={(e) => {
            if(searchPayload.length<=1){
              return;
            }
            if (e.key === "Enter") {
              actionSearch();
            }
          }}
          onChange={(e) => {setSearchPayload(e.target.value)}} className="w-[150px] bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" placeholder="Type here..."/>
          <button onClick={() => {actionSearch()}} className="ml-2 bg-transparent text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
          {t('ActionButton_search')}
          </button>
        </div>
      </div>
    </header>
  );
}

export default HeaderComponent;
