function TripleCardComponent() {
  return (
      <div className="TripleCardComponent flex flex-col md:flex-row items-center md:items-start md:text-left">
        <div className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden mt-[20px] shadow-lg">
            <div className="relative w-[300px] h-[200px] bg-cover bg-[url('https://www.gala.fr/imgre/fit/~1~gal~2022~05~05~1384b514-5368-4f52-a6bb-4ba926a56f85.jpeg/2715x2001/quality/80/yannick-noah.jpeg')]">
                <span className="absolute top-0 left-0 text-8xl">🥉</span>
            </div>
            <div className="p-2 h-[50px]">
                <h6 className="text-lg">Yannick Noah</h6>
                <div className="flex flex-wrap gap-2 mt-2">
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Sportif</div>
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Tennis</div>
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Chanteur</div>
                </div>
            </div>
        </div>
        <div className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden mt-[20px] md:mt-[80px] shadow-lg ml-[0px] md:ml-[-75px]">
            <div className="relative w-[300px] h-[200px] bg-cover bg-[url('https://media.zenfs.com/fr/closermag.fr/059675adb75b645da7fede6b2f7a9bc1')]">
                <span className="absolute top-0 left-0 text-8xl">🥈</span>
            </div>
            <div className="p-2 h-[50px]">
                <h6 className="text-lg">Léon Marchand</h6>
                <div className="flex flex-wrap gap-2 mt-2">
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Sportif</div>
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Tennis</div>
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Chanteur</div>
                </div>
            </div>
        </div>
        <div className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden mt-[20px] md:mt-[160px] shadow-lg ml-[0px] md:ml-[-75px]">
            <div className="relative w-[300px] h-[200px] bg-cover bg-[url('https://www.parismatch.com/lmnr/var/pm/public/media/image/2023/11/15/10/gettyimages-1492102030.jpg?VersionId=s7WRPEgK.JWrJkCYaPstUplHzApnYBzy')]">
                <span className="absolute top-0 left-0 text-8xl">🥇</span>
            </div>
            <div className="p-2 h-[50px]">
                <h6 className="text-lg">Marion Cotillard</h6>
                <div className="flex flex-wrap gap-2 mt-2">
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Sportif</div>
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Tennis</div>
                    <div className="rounded-2xl text-sm font-semibold p-1 px-2 bg-[#F1F1F1] text-[#222222]">Chanteur</div>
                </div>
            </div>
        </div>
      </div>
  );
}

export default TripleCardComponent;
