import axios from "axios";
import { useEffect, useState } from "react";

function VoteResultsComponent(props) {
    const [pros, setPros] = useState(1);
    const [cons, setCons] = useState(1);

    useEffect(() => {
        if(!props.peopleID){
            return;
        }

        (async function() {
            try {
              const params = new URLSearchParams({"p_id": props.peopleID });
              const url = `https://api.people-ranking.com/api/getVote?${params.toString()}`;
              const response = await axios.get(url);
              
              const data = response.data;
    
              data.forEach(element => {
                if(element.p_vote_type===-1){
                    if(element.amount){
                        setCons(element.amount);
                    }
                }

                if(element.p_vote_type===1){
                    if(element.amount){
                        setPros(element.amount);
                    }
                }
              });
            } catch (error) {
              console.error('Error while fetching: ', error);
            }
        })();

    }, [props.peopleID])
    return (
        <div className="flex">
            <div className={"grow-["+pros+"] flex items-center justify-center h-10 min-w-[25px] mt-8 bg-[#78C091] text-white text-sm border border-slate-200 align-center transition duration-300 ease shadow-sm"}>{(pros/(pros+cons)).toFixed(1) * 100}%</div>
            <div className={"grow-["+cons+"] flex items-center justify-center h-10 min-w-[25px] mt-8 bg-[#B6174B] text-white text-sm border border-slate-200 transition duration-300 ease shadow-sm"}>{(cons/(pros+cons)).toFixed(1) * 100}%</div>
        </div>
    );
  }
  
  export default VoteResultsComponent;
  