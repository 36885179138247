import './App.css';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Home from './components/Home';
import axios from 'axios';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import './i18n';
import PeoplePage from './pages/PeoplePage/PeoplePage';
import { useEffect } from 'react';
import {v4 as uuidv4} from 'uuid';
import CategoriePage from './pages/CategoriePage/CategoriePage';
import MostRankPage from './pages/MostRankPage/MostRankPage';
axios.defaults.withCredentials = true;

function App() {

  useEffect(() => {
    if(!localStorage.getItem("voteGuard")){
      localStorage.setItem("voteGuard", uuidv4());
    }
  }, [])

  return (
    <div className="App">
        <BrowserRouter className="lateralNavbar" basename={"/"}>
        <HeaderComponent/>
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/people/:id" element={<PeoplePage />}/>
              <Route path="/categorie/:id" element={<CategoriePage />}/>
              <Route path="/top/:type" element={<MostRankPage />}/>
              <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"/>
    </div>
  );
}

export default App;
