import RankListComponent from '../../components/RankListComponent/RankListComponent';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import VoteResultsComponent from '../../components/VoteResultsComponent/VoteResultsComponent';
import axios from 'axios';


function PeoplePage() {
  const navigate = useNavigate();
  let { id } = useParams();

  const [name, setName] = useState(false);
  const [description, setDescription] = useState(false)
  const [peopleID, setPeopleID] = useState(false);
  const [voteAvailable, setVoteAvailable] = useState(false);

  const getRandomPeople = function(){
    (async function() {
      const params = new URLSearchParams({"vote_guard": localStorage.getItem("voteGuard")});
      const url = `https://api.people-ranking.com/api/getPeople?${params.toString()}`;
      const response = await axios.get(url);    
      const data = response.data;

      navigate(`/people/${data.people_name.replaceAll(" ", "-")}-${data.people_id}`);
    })();
  }

  useEffect(() => {
    if(!id){
      return;
    }
    
    let pId = id.split("-").at(-1);
    if(!isNaN(pId)){
      (async function() {
        try {
          const params = new URLSearchParams({"p_id": pId, "vote_guard": localStorage.getItem("voteGuard")});
          const url = `https://api.people-ranking.com/api/getPeople?${params.toString()}`;
          const response = await axios.get(url);
          const data = response.data;

          if(data){
            setVoteAvailable(data.vote_available);
            setName(data.people_name);
            setDescription(data.p_desc_text);
            setPeopleID(data.people_id);
            fetchCategories(pId);
          }
        } catch (error) {
          console.error('Error while fetching: ', error);
        }
      })();
    } else {
      (async function() {
        try {
          const params = new URLSearchParams({"p_name": id });
          const url = `https://api.people-ranking.com/api/getPeople?${params.toString()}`;
          const response = await axios.get(url);
          const data = response.data;

          if(data.people_id&&data.people_name){
            navigate(`/people/${data.people_name.replaceAll(" ", "-")}-${data.people_id}`);
          }
        } catch (error) {
          console.error('Error while fetching: ', error);
        }
      })();
    }
  }, [id, navigate])

  const fetchCategories = function(id){
    (async function() {
      const params = new URLSearchParams({"p_id": id });
      const url = `https://api.people-ranking.com/api/getCategories?${params.toString()}`;
      const response = await axios.get(url);

      const data = response.data;
      console.log(data);
    })();
  }

  const addVote = function(type){
    (async function() {
      let pId = id.split("-").at(-1);
      if(!isNaN(pId)){
        setVoteAvailable(false);
        const params = new URLSearchParams({"p_id": pId, "type": type, "vote_guard": localStorage.getItem("voteGuard")});
        const url = `https://api.people-ranking.com/api/addVote?${params.toString()}`;
        axios.get(url);
      }
    })();
  }

  return (
      <div className="Home">
        <div className="flex flex-col md:flex-row m-[0px] md:m-[50px] p-[0px] md:p-[50px] gap-4 bg-zinc-200 shadow-md">
          <div className="w-full md:w-[50%] p-6">
            <h1 className="leading-[3rem] text-[3rem] font-black">{name}</h1>
            <p className='w-full md:w-[65%] text-lg font-medium mt-8'>{description}</p>
            {
              voteAvailable ?
              <>
                <div className="flex flex-col md:flex-row gap-2">
                  <button onClick={() => (addVote(1))} className="w-full md:w-1/2 mt-2 md:mt-8 bg-[#78C091] text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
                    J'adore
                  </button>
                  <button onClick={() => (addVote(-1))} className="w-full md:w-1/2 mt-1 md:mt-8 bg-[#B6174B] text-white text-sm border border-slate-200 rounded-md p-3 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
                    Je n'aime pas
                  </button>
                </div>
                <button onClick={() => {getRandomPeople();}} className="mt-4 bg-[#0A1128] text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
                  Another people
                </button>
              </>
              :
              <>
                <VoteResultsComponent peopleID={peopleID}/>
                <button onClick={() => {getRandomPeople();}} className="mt-4 bg-[#0A1128] text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
                  Another people
                </button>
              </>
            }
            
          </div>
          <div className="w-full md:w-1/2 flex justify-center flex-col md:flex-row gap-2">
            <div className={"max-h-80 rounded-md md:max-w-[33%] w-full md:w-1/3 h-[250px] md:h-50 bg-cover bg-center bg-[url('https://cdn.people-ranking.com/img/"+peopleID+"/1.webp')]"}></div>
            <div className={"max-h-80 hidden md:block rounded-md md:max-w-[33%] md:w-1/3 h-[250px] md:h-50 bg-cover bg-center bg-[url('https://cdn.people-ranking.com/img/"+peopleID+"/2.webp')]"}></div>
            <div className={"max-h-80 hidden md:block rounded-md md:max-w-[33%] md:w-1/3 h-[250px] md:h-50 bg-cover bg-center bg-[url('https://cdn.people-ranking.com/img/"+peopleID+"/3.webp')]"}></div>
          </div>
        </div>
        <div className='p-[50px] flex flex-col md:flex-row gap-4 w-full'>
          <RankListComponent rankType="most_liked" rankData="1"/>
          <RankListComponent rankType="most_disliked" rankData="-1"/>
          <RankListComponent rankType="most_trendy" rankData="2"/>
        </div>
      </div>
  );
}

export default PeoplePage;
