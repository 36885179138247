import RankListComponent from '../../components/RankListComponent/RankListComponent';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


function MostRankPage() {
  let { type } = useParams();

  const [label, setLabel] = useState("")
  const [peopleList, setPeopleList] = useState([]);
  const [id, setID] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if(id===false){
      return;
    }
    
    (async function() {
      const params = new URLSearchParams({"ranking_type": id });
      const url = `https://api.people-ranking.com/api/getTopRanking?${params.toString()}`;
      const response = await axios.get(url);

      const data = response.data;
      setPeopleList(data);
    })();
  }, [id])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!type){
        return;
    }

    switch(type){
        case "most_liked":
            setLabel("RankListComponent_label_most_liked");
            setID(1);
            break;
        case "most_disliked":
            setLabel("RankListComponent_label_most_disliked");
            setID(-1);
            break;
        case "most_trendy":
            setLabel("RankListComponent_label_most_trendy");
            setID(2);
            break;
        default:
            setLabel("RankListComponent_label_most_liked");
            setID(1);
            break;
    }
}, [type])

  return (
      <div className="Home">
        <div className="flex p-[50px] gap-4 bg-zinc-200 shadow-md">
          <div className="p-6">
            <h1 className="leading-[3rem] text-[3rem] font-black">{t(label)}</h1>
            <div className="flex flex-wrap gap-2 mt-4 justify-center">
              {peopleList.map((r,k) => {
                return (
                  <button onClick={() => {window.location = `/people/${r.people_name.replaceAll(" ", "-")}-${r.people_id}`}} key={k}
                    style={{background: `url("https://cdn.people-ranking.com/img/${r.people_id}/1.webp")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"}}
                    className="flex flex-col bg-[#FFFFFF] w-[300px] h-[300px] rounded-2xl overflow-hidden shadow-lg">
                    <div className="p-2 h-[50px] w-full bg-[#FFFFFF] mt-auto">
                        <h6 className="text-lg">{r.people_name}</h6>
                    </div>
                  </button>
                )
              })}
            </div>
          </div>
        </div>
        <div className='p-[50px] flex gap-4 w-full'>
          <RankListComponent rankType="most_liked" rankData="1"/>
          <RankListComponent rankType="most_disliked" rankData="-1"/>
          <RankListComponent rankType="most_trendy" rankData="2"/>
        </div>
      </div>
  );
}

export default MostRankPage;