import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend'; // To load translations
import LanguageDetector from 'i18next-browser-languagedetector'; // Optional: Detects language from browser settings

i18n
  .use(HttpApi) // Loads translations from a backend
  .use(LanguageDetector) // Detects language automatically (browser or path)
  .use(initReactI18next) // Passes i18n down to React
  .init({
    supportedLngs: ['en'], // List of languages you support
    fallbackLng: 'en', // Default language if not detected or missing
    detection: {
      order: ['cookie', 'localStorage', 'path', 'navigator'], // Adjust order as needed
      caches: ['cookie'], // Store language in cookies for future sessions
      transKeepBasicHtmlNodesFor: ["br", "strong"]
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to translation files
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;